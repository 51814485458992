import React from 'react';
import Layout from '../components/layout';
import './resume.scss';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';

import { IconContext } from 'react-icons';
import { AiOutlineMail } from 'react-icons/ai';
import { AiOutlineEnvironment } from 'react-icons/ai';
import { AiOutlineHome } from 'react-icons/ai';
const resume = ({ data }) => {
  console.log(data.resumeExperience.edges);
  let experienceView = null;
  experienceView = (
    <div className="experience">
      <div className="experience-title title">EXPERIENCE</div>
      {data.resumeExperience.edges.map((item) => {
        return (
          <div className="work-item" key={item.node.id}>
            <div className="work-title secondary-title">{item.node.title}</div>
            <div className="work-period desc">{item.node.period}</div>
            <div className="work-company content">{item.node.company}</div>
            <div className="work-location desc">{item.node.location}</div>
            <div className="work-desc content">{item.node.workDescription}</div>
            <ul className="work-details desc">
              {item.node.workDetail.map((detail) => {
                return (
                  <li className="detail" key={detail}>
                    {detail}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );

  let honorView = null;
  const honorList = data.resumePage.honor;
  honorView = (
    <div className="honor">
      <div className="honor-title title">HONORS</div>
      {honorList.map((item) => {
        return (
          <div className="honor-item" key={item.id}>
            <div className="item-name secondary-title">{item.detail.name}</div>
            <div className="item-time desc">{item.detail.time}</div>
            <div className="item-company content">{item.detail.company}</div>
            <div className="item-location desc">{item.detail.location}</div>
          </div>
        );
      })}
    </div>
  );

  let educatView = null;
  const educatList = data.resumePage.education;
  educatView = (
    <div className="educate">
      <div className="educate-title title">EDUCATION</div>
      {educatList.map((item) => {
        return (
          <div className="educate-item" key={item.id}>
            <div className="item-degree secondary-title">
              {item.detail.degree}
            </div>
            <div className="item-school content">{item.detail.school}</div>
            <div className="item-major desc">{item.detail.major}</div>
          </div>
        );
      })}
    </div>
  );

  let skillView = null;
  const skillList = data.resumePage.skills;
  skillView = (
    <div className="skill">
      <div className="skill-title title">SKILLS</div>
      {skillList.map((item, index) => {
        return (
          <div className="skill-tag desc" key={index}>
            {item}
          </div>
        );
      })}
    </div>
  );
  return (
    <Layout>
      <SEO title="Resume" />
      <div className="resume-page">
        <div className="resume-header">
          <div className="herder-left">
            <div className="header-name">{data.resumePage.name}</div>
            <div className="header-tag">{data.resumePage.desc}</div>
          </div>

          <div className="header-contact">
            <div className="location contact-item">
              <IconContext.Provider value={{ color: '#21324d', size: '1.2em' }}>
                <div className="icon">
                  <AiOutlineEnvironment />
                </div>
              </IconContext.Provider>
              <div className="text">{data.resumePage.location}</div>
            </div>
            <a
              className="email contact-item"
              href="mailto:kai@zhukai.me"
              target="_blank"
              rel="noreferrer noopener"
            >
              <IconContext.Provider value={{ color: '#21324d', size: '1.2em' }}>
                <div className="icon icon-link">
                  <AiOutlineMail />
                </div>
              </IconContext.Provider>
              <div className="text text-link">{data.resumePage.email}</div>
            </a>
            <a
              className="website contact-item"
              href="http://zhukai.me/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <IconContext.Provider value={{ color: '#21324d', size: '1.2em' }}>
                <div className="icon icon-link">
                  <AiOutlineHome />
                </div>
              </IconContext.Provider>
              <div className="text text-link">{data.resumePage.website}</div>
            </a>
          </div>
        </div>
        <div className="resume-body">
          {experienceView}
          {honorView}
        </div>
        <div className="resume-right">
          <div className="about">
            <div className="about-title title">ABOUT</div>
            <ul className="about-content desc">
              {data.resumePage.about.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          </div>
          {educatView}
          {skillView}
        </div>
        <div className="resume-footer"></div>
      </div>
    </Layout>
  );
};

export default resume;
export const query = graphql`
  {
    resumeExperience: allContentfulResumeExperience(
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          title
          period
          company
          workDescription
          workDetail
          location
          id
        }
      }
    }
    resumePage: contentfulResumePage {
      name
      desc
      website
      location
      email
      skills
      about
      education {
        detail {
          degree
          major
          school
        }
        id
      }
      honor {
        id
        detail {
          name
          time
          company
          location
        }
      }
    }
  }
`;
